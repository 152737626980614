:root {
    --radius: 2px;
    --baseFg: dimgray;
    --baseBg: white;
    --accentFg: #006fc2;
    --accentBg: #bae1ff;
  }

//   select {
//     appearance: none;
//     background-color: transparent;
//     border: none;


 
//   }
    
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='navy' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 50%;
    border: 1px solid #dfdfdf;
    border-radius: 2px;
    padding: 1rem;
    width: 100%!important;
  }

  select[multiple] {
    padding-right: 0;
    white-space: normal;
    height: 6rem;
  }
