.advance-groups {
    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        flex-wrap: wrap;
        padding: 4rem 1rem;

        column-gap: 5rem;
        row-gap: 20%;
        overflow-y: auto;
        &__card {
            border-radius: 1.3rem;
            background-color: $blue-sky3;
            width: 30%;
            height: 40%;
            flex-direction: column;
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 2rem 0;
            box-shadow: 0px 3px 6px #00000029;
            cursor: pointer;
            transition: all 0.25s ease-in-out;

            &:hover {
                transform: scale(1.04);
                
            }
            h2 {
                text-align: center;
                font-size: 2rem;
                color: #0d4a85;
                font-weight: 600;
                width: 70%;
            }
            img {
                max-width: 50%;
            }
        }
    }
}
