.mainContainer {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;

    &.modal-active {
        height: 100%;
        width: 100%;
        padding: 0;
        &-expense {
            &::before {
                z-index: 70;
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(27, 27, 27, 0.4);
            }
        }
        &::after {
            z-index: 20;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(27, 27, 27, 0.4);
        }
    }
    .content {
        display: flex;
        width: 100%;
        padding: 3.5rem 4rem 5.5rem 2rem;
        height: calc(100vh - 15rem);
        justify-content: center;
    }
}
