.gra__ {
    &container {
        
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        overflow: hidden;
        
        
        &__headers {
            height: 10%;
            display: flex;
            justify-content: space-between;
        }
        &__body {
            height: 85%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        &__details {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            &__headers {
                display: flex;
                width: 100%;
                height: 10%;
                align-items: flex-start;
                justify-content: space-between;
                &__searchAndBack {
                    display: flex;
                    align-items: center;
                    width: 50%;

                    .sea__container {
                        width: 100%;
                    }

                    .btn__back {
                        margin-right: 1rem;
                    }
                }

                .filters__container {
                    width: 50%;
                    justify-self: flex-end;
                    display: flex;
                    align-items: center;

                    &__dates {
                        justify-content: space-around !important;
                        .month {
                            width: 50%;
                        }
                        .year {
                            width: 30%;
                        }
                    }
                }
            }
            &__informationStudent {
                display: flex;
                flex-direction: column;
                width: 65%;
                height: 30%;
                margin-bottom: 1rem;
            }
            &__table {
                width: 100%;
                height: 50%;
            }
        }
    }
}
