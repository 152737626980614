.sidebar {
    display: flex;
    flex-direction: column;
    height: 70%;
    flex: 0 0 25%;
    font-size: 2.3rem;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;

    &::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
    }

    &__link {
        display: flex;
        align-items: center;
        color: $gray-bars;
        margin-top: 4rem;
        &:first-child {
            margin-top: 0;
        }

        &__icon {
            width: 10%;
        }
        &.active {
            color: $mustard;
        }
    }

    &__submenu {
        display: flex;
        flex-direction: column;
        &__link {
            width: 100%;
            height: 6.3rem;
            display: flex;
            align-items: center;

            color: $blue-btn-text;
            font-size: 2.5rem;
            padding-left: 5rem;
            // margin-bottom: 1.5rem;

            &:first-child {
                margin-top: 2rem;
            }
            &__icon {
                font-size: 2.5rem;
                width: 12%;
            }
            &.active {
                color: $blue-active-text !important;
                background-color: $blue-sky3;
                transition: color 0.4s;
            }
            &:hover {
                color: lighten($color: $blue-btn-text, $amount: 8);
                transition: color 0.4s;
            }
        }
    }
}
