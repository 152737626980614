//Fonts:
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Philosopher&display=swap");

//Colors:
$primary: "";
$dark-grey: #363636;
$light-grey: #d8d8d8;
$white: #fff;
$color-black: #000000;
$gray-navbar: #dcdcdc;
$gray-inputs: #e6e6e6;
$gray-inputsText: #535353;
$gray-logout: #848484;
$gray-bars: #707070;
$gray-border: #9e9e9e;
$gray-radioButtons: #d9d9d9;
$filterPaid-back: #e9eaeb;
$activeBorder: #00c621;
$blue-heroLogin: #012e5a;
$gray-inputLogin: #636363;
$gray-inputBorder: #c5c5c5;
$gray-panelBorder: #a2a2a2;
$mustard: #c6b300;
$yellow-radius: #f1e982;
$color-gray: #ababab;
$main-circles: #004c97;
$blue-aqua: #3275b5;
$blue-btn-text: #00407e;
$green-btn-text: #049a1d;
$red-btn-text: #ef2d2d;
$blue-sky: #7ca5ce;
$blue-sky2: #b2d6fb;
$blue-sky3: #cde6ff;
$blue-active-text: #156cc1;
$blue-solid: #043b71;
$blue-table: #aad4ff;
$blue-icon: #d5e9fe;
$blue-button: #6b8aa8;
$searched-table: #82e893;
$schedule-back: #f8f7f3;
$red-trash: #ff0000;
$red-table: #f37777;
$schedule-border: #c8c9c7;
$blue-btn-border-feed: #507397;
$blue-btn-text-feed: #3e72a5;
$input-disabled: #dbdbdb;
//Payments
$gray-changePage: #a5a5a5;
// $blue-sky3: #d4e9fe;
$blue-sky4: #aad4ff;
$shadow-overTexture: #828282e6;
$shadow-search: #00000029;
$border-search-des: #c7c7c7;
$red-debt: #f37777;
$fertilizer-text: #375777;
$gray-disabled: #edeeee;
$gray-disabled-title: #a8a8a8;
