.radioButtonList {
    width: 45%;
    display: flex;
    flex-direction: column;
    &__container {
        display: flex;
        flex-direction: column;
        height: 100%;
        // width: 100%;
        border-radius: 10px;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        font-size: 2rem;
        font-weight: 600;
        justify-content: space-evenly;
        background-color: $blue-sky3;
        position: relative;

        &.error {
            border: 1px solid red;
        }
        .errorMessage {
            position: absolute;
            align-self: flex-start;
            // top: 4.6rem;
            top: 102%;
            color: red;
            font-size: 1.5rem;
            font-weight: 600;
            border: none;
            background-color: transparent;
        }
    }
}
