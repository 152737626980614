.btn {
    cursor: pointer;
    border: none;
    background: none;
    &-primary {
        color: $white;
        background-color: $blue-btn-text;
        border-radius: 10px;
        font-size: 2rem;
        font-weight: 500;
        width: 10rem;
        height: 4rem;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        box-shadow: none;
        border: none;
        cursor: pointer;
    }

    &-primary.active {
        background-color: $blue-btn-text;
    }

    &-cancel {
        color: $white;
        background-color: $red-btn-text;
        border-radius: 10px;
        font-size: 2rem;
        font-weight: 500;
        justify-content: center;
        width: 11%;
        height: 60%;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        box-shadow: none;
        border: none;
        cursor: pointer;
    }
}

// text-align: center;
// text-decoration: none;
// border: none;
// background-color: transparent;
// }
.btn__actions {
    width: 90%;
    height: 8%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn-formAction {
    width: 7%;
    height: 60%;
    background-color: $gray-inputs;
    border-radius: 5px;
    margin-left: 1%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    span {
        color: $gray-inputLogin;
        font: normal normal normal 14px Segoe UI;
    }

    i {
        color: $gray-logout;
    }

    // &:hover.btn-formAction span {
    //     color: green;
    // }
}
.btn__hamburContainer {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    top: 0;
}
.btn-hambur {
    font-size: larger;
    border: 1px solid $gray-inputs;
    color: $gray-panelBorder;
    height: 55%;
    transition: all 0.3s;

    &:hover {
        border: 1px solid darken($color: $gray-inputs, $amount: 20);
        color: darken($color: $gray-panelBorder, $amount: 20);
        transition: all 0.3s;
    }
}

.btn__add {
    height: 100%;
    background-color: $blue-btn-text;
    border-radius: 10px;
    font-size: calc(0.55em + 0.4vw);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    span,
    i {
        color: $white;
    }
}
// Buttons table

.btnArrow {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.7rem;
    font-size: 2rem;
}

.btnTable {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        padding-right: 0.3rem;
        font-size: 0.8rem;
        color: initial;
        font-size: 1.8rem;
    }
    i {
        font-size: 2rem;
        text-align: center;
    }

    &__container {
        &__editDelete {
            display: flex;
            gap: 2rem;
            justify-content: space-between;
            // background-color: $white !important;
        }
    }
}

.btn__back {
    width: 4rem;
    height: 4rem;
    background: RGB(255, 255, 255) 0% 0% no-repeat padding-box;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 1px 8px #00000021;

    border-radius: 1.5rem;
    color: #00407e;
    padding: 0.1em 0.1em !important;

    & i {
        font-size: 2rem;
    }
}

.btnBlue {
    color: $blue-btn-text !important;
}
.btnRed {
    color: $red-btn-text !important;
}
.btnGreen {
    color: $green-btn-text !important;
}

.btnFeedContainer {
    display: flex;
    align-items: center;
    width: 25rem;
    height: 4rem;
    margin: auto;
    justify-content: space-between;
}
.btnCleanFeed,
.btnSubmitFeed {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 45%;
    border-radius: 1rem;
    font-size: 1.7rem;
}
.btnCleanFeed {
    background-color: $white;
    border: 1px solid $blue-btn-border-feed;
    color: $blue-btn-text-feed;
}
.btnSubmitFeed {
    background-color: #3e72a5;
    color: $white;
}

.btnAssignGroup {
    color: $white;
    background-color: $blue-btn-text;
    font-size: 2rem;
    font-weight: 500;
    width: 100%;
    height: 100%;
    padding: 1rem 3rem;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    border-radius: 10px;
}

// .activeGuide {
//     background-color: $blue-btn-text;
//     color: $white;
// }

.disableGuide {
    pointer-events: none;
    color: $white;
    background-color: $blue-button;
}

.btn-expenses {
    height: 5rem;
}