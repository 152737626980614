.assign {
    &__container {
        display: flex;
        height: 100%;
        width: 100%;
        flex-direction: column;
        &__header {
            display: flex;
            height: 10%;
            align-items: center;
            justify-content: center;
            position: relative;
            margin-bottom: 2rem;
            &__title {
                font-size: 2.4rem;
                color: $blue-btn-text;
            }
            &__button {
                position: absolute;
                left: 0.5rem;
            }
        }

        &__content {
            height: 90%;
            padding: 2.4rem;
            margin: 2rem 4rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            background-color: $blue-sky3;

            .sea__container {
                width: 100%;
                height: 4.5rem;
            }

            &__list {
                background-color: white;
                width: 100%;
                flex: 1;
                margin-top: 2rem;
                display: flex;
                flex-direction: column;
                overflow-y: auto;
                position: relative;

                &__button {
                    button {
                    }
                    select {
                    }
                    // display: inline-block;
                }

                &__item {
                    display: flex;
                    margin-bottom: 1.5rem;
                    align-items: center;

                    height: 5rem;
                    width: 100%;
                    padding: 0.8rem 2rem;
                    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
                    font-size: 2.5rem;

                    position: relative;
                    input {
                        margin-right: 3rem;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 2.5rem;
                        height: 100%;

                        // &::before {
                        //     position: absolute;
                        //     content: "";
                        //     width: 2rem;
                        //     height: 2rem;
                        //     border-radius: 50%;
                        //     border: 1px solid $color-black;
                        //     background-color: $white;
                        // }

                        // &:checked:before {
                        //     background-color: green;
                        // }
                    }

                    label {
                        flex: 1;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
                        text-transform: uppercase;
                    }
                }
            }
            &__submit {
                display: flex;
                width: 100%;
                margin-top: 5rem;
                justify-content: space-between;
                label {
                    font-size: 1.8rem;
                    position: absolute;
                    align-self: flex-start;
                    top: -2.5rem;
                    left: 0.7rem;
                }

                button {
                    width: 20%;
                }

                &__select {
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    font-size: 1.7rem;

                    border: 1px solid #dbdbdb;
                    background-color: $white;
                    box-shadow: 0px 3px 6px #0000001a;
                    border-radius: 10px;
                    border-color: #dbdbdb;
                    width: 25%;
                    select {
                        margin-top: 1rem;
                        border: none;
                    }
                }
                &__dateInput {
                    position: relative;
                    font-size: 1.7rem;

                    border: 1px solid #dbdbdb;
                    background-color: $white;
                    box-shadow: 0px 3px 6px #0000001a;
                    border-radius: 10px;
                    border-color: #dbdbdb;

                    display: flex;
                    justify-content: center;
                    align-items: center;
                    // height: 10;

                    input {
                        margin-top: 1rem;
                        width: 100%;
                        height: 100%;
                        padding: 1rem;
                        font-size: 1.8rem;
                        border: none;
                        background-color: transparent;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &::placeholder {
                            color: #a7a5a5;
                        }

                        &.disabled {
                            background-color: $input-disabled;
                            opacity: 0.5;
                        }
                    }
                }
            }
        }
    }
}
