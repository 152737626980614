*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

.swal2-popup {
    font-size: 1.4rem !important;
}

.swal2-container {
    zoom: 1.5;
}

html {
    // This defines what 1rem is
    font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;

    @include respond(tab-land) {
        // width < 1200?
        font-size: 56.25%; //1 rem = 9px, 9/16 = 50%
    }

    @include respond(tab-port) {
        // width < 900?
        font-size: 50%; //1 rem = 8px, 8/16 = 50%
    }

    @include respond(big-desktop) {
        font-size: 75%; //1rem = 12, 12/16
    }
}

body {
    box-sizing: border-box;
    @include respond(tab-port) {
        padding: 0;
    }
}

input:focus,
select:focus {
    outline: none;
}

input {
    border: none;
}

a {
    text-decoration: none;
}

.styledInput {
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #c7c7c7;
    border-radius: 8px;
    padding: 0;
    margin: 0;
    height: 4rem;
    width: 100%;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.general__titleSection {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 0.3rem;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    margin-left: 1%;
}

.general__title-h4 {
    font-size: 2rem;
    margin: 1.8rem;
    align-self: center;
    color: $color-black;
    font-weight: 600;
}

*,
.scroll {
    &::-webkit-scrollbar {
        width: 1px;
    }
  

    &::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 1px;
    }

    &::-webkit-scrollbar-thumb:active {
        background-color: #999999;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #b3b3b3;
    }

    /* Estilos track de scroll */
    &::-webkit-scrollbar-track {
        background: transparent;
    }
}


.errorMessage {
    color: red;
    font-size: 1.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
}
