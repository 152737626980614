.table {
    height: 100%;
    width: 100%;
    border: none;
    display: flex;
    flex-direction: column;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    grid-row-gap: 0%;

    &__headers {
        display: flex;
        text-align: center;
        width: 100%;
        height: 30px;
        margin-bottom: 1.2rem;
        &__cell {
            padding: 0.4rem;
            color: $color-black;
            font-size: 2rem;
            padding-bottom: 0;
            font-weight: 500;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        height: 95%;
        overflow: auto;
        flex: 1 1 0;
        flex-basis: 0px;
        position: relative;
        &__row {
            display: flex;
            width: 100%;

            margin: 0.7rem 0;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;
            position: relative;

            &__cell {
                display: flex;
                padding: 1rem 2rem;
                font-size: 2rem;
                align-items: center;

                input {
                    background-color: red;
                }
            }

            div:nth-child(odd) {
                background-color: $blue-sky3;
            }
            div:nth-child(even) {
                background-color: $white;
            }
            div:first-child {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
            }
            div:last-child {
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
            }

            &__cell.searched {
                background-color: $searched-table !important;
                font-weight: 600;
            }
        }
    }

    .empty {
        &__headers {
            opacity: 0.5;
        }
        &__body {
            opacity: 0.6;

            .table__body__row {
                div:nth-child(even) {
                    background-color: #f4fafe;
                }
            }
        }
    }
}

.skeleton {
    &__row {
        cursor: progress;
        background: linear-gradient(0.25turn, transparent, #699999, transparent) 0% 0% / 100% 100% no-repeat;
        background-position: -400px 0;
        background-size: 400px auto;
        animation: loading 2s infinite;
    }
    &__cell {
        opacity: 0.8;
    }
}

@keyframes loading {
    to {
        background-position: calc(100% + 400px) 0;
    }
}
