.stuInfo {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        border-radius: 10px;
        background-color: $blue-sky3;

        font-size: 2rem;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        text-transform: uppercase;

        &__containerTwo {
            width: 90%;
            height: 90%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            &__row {
                display: flex;
                width: 100%;
                &__header {
                    font-weight: bold;
                    width: 23%;
                }
                &__info {
                    text-align: inherit;
                    font-weight: 500;
                    // padding-left: 1em;
                    width: 77%;
                }
            }
        }
    }
}

// &studentInformation {
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;
//     height: 32.5%;
//     .data {
//         background-color: $blue-sky3;
//         border-radius: 7px;
//         height: 85%;
//         p {
//             font-size: calc(6.7px + 0.5vw);
//             text-transform: uppercase;
//             font-weight: 600;
//         }
//     }
// }
