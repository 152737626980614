.texture {
    background-color: rgb(255, 255, 255);
    height: 100vh;
    width: 100vw;
    display: inline-block;
    position: absolute;
    z-index: -100;
}
.texture::after {
    content: "";
    height: 100vh;
    width: 100vw;
    background: #012e5a;
    clip-path: polygon(0 90%, 100% 13%, 100% 100%, 0% 100%);
    position: absolute;

}
