.navbar {
    display: flex;
    width: 95%;
    justify-content: space-between;
    align-items: center;
    height: 15rem;
    font-family: "Philosopher", sans-serif;
    // text-align: right;
    position: relative;
    color: $blue-heroLogin;

    &__logo {
        display: flex;
        align-items: center;
        height: 100%;
        width: 25%;
      
        img {
            max-width: 90%;
            max-height: 90%;
        }
        &__text { 
            color: $color-black;
            font-size: 2.7rem;
            margin-left: 1rem;
            letter-spacing: 0.5px;
        }
    }

    &__text {
        position: absolute;
        z-index: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        font-size: 1.7rem;
        font-weight: bold;
        letter-spacing: .3rem;
        font-family: 'Segoe UI', Tahoma;
        text-transform: uppercase;
        
    }

    &__logout {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: flex-end;
        height: 100%;
        width: 10%;
        font-size: 5rem;

        z-index: 1;
    }
}
