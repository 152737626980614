.auth__container {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.auth__login {
    width: 45%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        // transition: all 10s;
    }

    &-inputs {
        position: relative;
        width: 45%;
        margin: 2% 0;

        input {
            width: 100%;
            font-family: "Open Sans", sans-serif;
            font-size: 2rem;
        }

        &-input {
            margin-bottom: 3%;
            border: none;
            border-bottom: 2px solid $gray-inputBorder;
            transition: all 0.2s ease-in-out;
            &:focus {
                border-color: $gray-inputLogin;
                color: darken($color: $gray-inputBorder, $amount: 20);
                transition: all 0.2s ease-in-out;
            }
            &:focus::placeholder {
                color: darken($color: $gray-inputBorder, $amount: 20);
                transition: all 0.2s ease-in-out;
            }
            &:focus ~ i {
                color: darken($color: $gray-inputLogin, $amount: 20);
                transition: all 0.2s ease-in-out;
            }
        }

        &-icon {
            position: absolute;
            top: 0;
            right: 0;
            font-size: 2rem;
            color: $gray-inputLogin;
        }
    }
    &-submit {
        background-color: $mustard;
        border-radius: 10px;
        height: 5rem;
        width: 45%;
        color: $white;
        transition: background-color 0.3s;

        &.btn {
            background-color: $mustard;
            font-size: 2rem;
        }

        &:hover {
            background-color: darken($color: $mustard, $amount: 3);
            transition: background-color 0.3s;
        }
    }
}

.auth__hero {
    background-color: $blue-heroLogin;
    width: 55%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-logo {
        width: 38%;
        height: auto;
        object-fit: fill;
    }
    &-text {
        font-size: calc(1rem + 1vw);
        width: 100%;
        color: white;
        text-align: center;
        margin-top: 2%;
        letter-spacing: 1px;
    }
}
