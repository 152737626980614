.makeAPay {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;

    &__headers {
        width: 100%;
    }

    &__body {
        width: 100%;
        flex: 1;
        margin-top: 3rem;
        display: flex;
        justify-content: space-between;
        position: relative;
        overflow: hidden;
        &__container {
            width: 45%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            &.right {
                justify-content: initial;
            }
            .matri__container {
                max-width: 33rem;
            }

            &__studentData {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 25rem;

                .stuInfo {
                    margin: 0 !important;
                }
            }

            &__cosaAPagar {
                position: absolute;
                top: 35%;
                left: 0;
                display: flex;
                width: 100%;
                height: 65%;
                background-color: $blue-sky3;
                display: flex;
                flex-direction: column;
                border-radius: 1rem;
                margin-top: auto;
                padding: 1rem;
                z-index: 10;

                &__searchBar {
                    background-color: white;
                    height: 10%;
                    border-radius: 1rem;
                    font-size: 2rem;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    padding: 1rem;
                    box-shadow: 0px 3px 6px #00000017;
                    &__input {
                        width: 100%;
                        height: 100%;
                        border: none;
                        background-color: transparent;
                        font-size: 1.8rem;
                        outline: none;
                        padding: 0 1rem;
                    }
                }

                &__items {
                    display: flex;
                    flex-direction: column;
                    background-color: white;
                    margin-top: auto;
                    border-radius: 1rem;
                    padding: 1rem;
                    font-size: 2rem;
                    height: 85%;
                    gap: 0.5rem;
                    overflow-y: auto;
                    &__item {
                        height: 4.5rem;
                        border-radius: 1rem;
                        border: 1px solid $blue-sky3;
                        padding: 1rem;
                        display: flex;
                        align-items: center;
                        transition: all 0.3s;

                        input {
                            visibility: hidden;
                        }

                        &:hover {
                            background-color: lighten($blue-sky3, 3%);
                        }

                        &.selected {
                            background-color: $blue-sky3;
                        }
                    }
                }
            }

            &__deposit {
                select {
                    font-size: 1.5rem;
                    font-weight: 600;
                    padding: 1rem;
                    background-color: $white;
                    border: 1px solid $gray-bars;
                    border-radius: 10px;
                }
                margin-bottom: 2rem;
            }

            &__card {
                p {
                    &.title {
                        font-size: 2rem;
                        color: $gray-bars;
                    }
                    font-size: 1.5rem;
                    font-weight: 600;
                }

                margin-bottom: 2rem;
            }

            &__concept {
                height: auto;

                &__buttons {
                    display: flex;
                    justify-content: space-between;
                    &.method {
                        justify-content: space-between;
                        button {
                            flex: 20%;
                            margin-right: calc(40% / 3);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }

            &__conceptToPay {
                display: flex;
                flex-direction: column;
                span {
                    display: flex;
                    background-color: $blue-sky3;
                    padding: 1rem;
                    font-size: 1.85rem;
                    border-radius: 10px;
                    text-transform: uppercase;
                    margin-top: 0.5rem;
                    height: 4rem;
                    display: flex;
                    align-items: center;

                    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
                }
            }

            &__total {
                margin: 4rem 0;
                height: 5rem;
                display: flex;
                align-items: center;

                &__price {
                    background-color: $color-black;
                    margin-left: 1.5rem;
                    clip-path: polygon(15% 0, 100% 0, 100% 100%, 15% 100%, 0 50%);
                    position: relative;
                    height: 100%;
                    width: 10rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    p {
                        clip-path: polygon(15% 0, 100% 0, 100% 100%, 15% 100%, 0 50%);
                        font-size: 2rem;
                        padding: 1rem 2rem;
                        height: calc(100% - 0.1rem);
                        width: 9.9rem;
                        background-color: $white;
                        position: absolute;
                    }
                }
            }

            &__money {
                display: flex;
                flex-direction: column;
                font-size: 1.5rem;
                height: 18rem;
                gap: 2rem;
                div {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                    label {
                        text-align: left;
                        // text-transform: uppercase;
                        opacity: 1;
                        font-weight: 700;
                        font-size: 2rem;
                    }
                    & .input {
                        padding: 1rem;
                        background-color: $white;
                        border: 1px solid $gray-bars;
                        border-radius: 10px;
                        font-size: 1.8rem;
                        &::placeholder {
                            font-size: 1.8rem;
                        }
                        &:focus {
                            border: 1px solid #00c621;
                        }
                    }
                }

                &.disabled {
                    opacity: 0;
                }
            }

            &__pay {
                align-self: center;
                display: flex;
                margin-top: 10rem;
                width: 100%;
                justify-content: space-around;
                align-items: center;
                button {
                    padding: 1rem 4rem;
                }
            }
        }
    }
}

.payTitle {
    font-size: 2rem;
    font-weight: bold;
}

.btn-makeAPay {
    padding: 1rem;
    background-color: $gray-border;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000017;
    background-color: darken($color: $white, $amount: 10);
    font-size: 1.8rem;
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    cursor: pointer;

    input {
        visibility: hidden;
        display: none;
    }
    i {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        color: darken($color: $white, $amount: 45);
        margin-left: 1rem;
    }

    &.active {
        background-color: $yellow-radius;
    }
}

.btn-bluePay {
    color: $white;
    background-color: $blue-button;
    border-radius: 8px;
    font-size: 2rem;
    font-weight: 500;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    padding: 1rem 2rem;
    transition: all .2s;
    &:hover {
        transition: all .2s;
        background-color: darken($color: $blue-button , $amount: 10);
    }
    &.active {
        background-color: $blue-btn-text;
        &:hover {
            transition: all .2s;
            background-color: darken($color:  $blue-btn-text , $amount: 10);
        }
    }
   
}

.btn-cancelPay {
    color: $white;
    background-color:red;
    border-radius: 8px;
    font-size: 2rem;
    font-weight: 500;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    padding: 1rem 2rem;
    border: none;
    cursor: pointer;
    transition: all .2s;
    &:hover {
        transition: all .2s;
        background-color: darken($color: red, $amount: 10);
    }
}
