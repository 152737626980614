.req__ {
    &container {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        font-size: 2rem;
        h4 {
            margin-top: 2rem;
            margin-bottom: 2rem;
        }
        &__header {
            width: 100%;
            display: flex;
            align-items: center;

            div {
                margin: 0 2% 0 2%;
                width: 60%;
            }

            p {
                width: 20%;
                font-size: calc(0.5rem + 0.5vw);
            }
        }
    }

    &header {
        display: flex;
        width: 100%;
    }
    
    &body {
        margin-top: 1em;
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 80%;

        &__student {
            width: 45%;
            display: flex;
            flex-direction: column;
        }
    }

    &footer {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 15%;
        align-items: center;
        &__checkHistory {
            background-color: transparent;
            color: $blue-btn-text;
            border: none;
            font-size: 2rem;
            font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
            span {
                margin-left: 0.5rem;
            }
        }
    }
}

.pretty-radio {
    position: relative;
    // margin-bottom: 8px;
}

.radio {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 24;
}

.radio-look {
    width: 1em;
    height: 1em;
    display: inline-block;
    vertical-align: middle;
    padding: 5px;
    border: 1px solid $gray-radioButtons;
    border-radius: 50%;
    position: relative;
    background-color: $white;
    margin: 0 1em;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.radio:checked + .radio-look {
    background: #c6b300;
    border: 3px solid #d9d9d9;
}

.active {
    opacity: 1;
    transition: all 0.3s;
}

.reqHistory__dates {
    display: flex;
    gap: 1em;

    & select {
        width: min-content;
        padding: 0.5em;
        width: 60%;
        box-shadow: 0px 3px 6px $shadow-search;
        border: 1px solid $filterPaid-back;
        border-radius: 5px;
        color: #00407e;
        max-height: 30px;
        & option {
            color: #000000;
            border-radius: 5px;
        }
    }
}
