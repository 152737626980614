.matri__ {
    &container {
        display: flex;
        flex-direction: column;

        input {
            border: 1px solid $gray-bars;
            border-radius: 7px;
            padding: 1rem;
            width: 100%;
            height: 3.5rem;
            text-transform: uppercase;
            font-size: 2rem;
        }
    }
}
