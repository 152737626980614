.feed {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    justify-content: space-between;
    &__headers {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        height: 9%;
        .sea__container {
            width: 60%;
        }
        .btn__add {
            font-size: 1.8rem;
            width: 15%;
            height: 80%;
            display: flex;
            align-items: center;
            justify-content: center;
            i {
                font-size: 1.5rem;
                color: #fff;
                margin-right: 1.3rem;
            }
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        height: 86%;
        width: 100%;
        justify-content: space-between;
        &__title {
            text-align: center;
            font-size: 2rem;
            margin: 1.8rem;
            align-self: center;
            color: $color-black;
            font-weight: 600;
        }
        &__content {
            width: 100%;
            height: 88%;
        }
    }
}
