.form__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    &__header {
        width: 100%;
        height: 10%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        &__title {
            position: absolute;
            align-self: center;
            font-size: 2rem;
            font-weight: 600;
            color: $color-black;
        }
    }

    &__body {
        width: 100%;
        height: 88%;
        position: relative;
        overflow: auto;
        &__section {
            position: relative;

            width: 100%;
            height: auto;

            border-radius: 10px;

            margin-bottom: 2rem;
            margin-top: 5rem;
            padding: 3rem 4rem;

            background-color: #cde6ff;

            // display: flex;
            // flex-wrap: wrap;
            align-items: center;

            &:first-of-type {
                margin-top: 3rem;
            }
            &__title {
                position: absolute;
                color: #00407e;
                align-self: flex-start;
                font-size: 1.5rem;
                font-weight: 600;
                top: -2.5rem;
                left: 1.3rem;
            }
            &__row {
                display: flex;
                width: 100%;
                min-height: 4.5rem;
                height: auto;
                justify-content: space-between;
                margin-top: 3.5rem;
                margin-bottom: 5rem;
                // margin-bottom: 1rem;
                &:first-of-type {
                    margin-top: 3rem;
                }

                &__inputContainer {
                    position: relative;
                    font-size: 1.7rem;

                    border: 1px solid #dbdbdb;
                    background-color: $white;
                    box-shadow: 0px 3px 6px #0000001a;
                    border-radius: 10px;
                    border-color: #dbdbdb;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &.error {
                        border-color: red;
                    }

                    label {
                        position: absolute;
                        align-self: flex-start;
                        top: -2.5rem;
                        left: 0.7rem;
                    }
                    input {
                        width: 100%;
                        height: 100%;
                        padding: 1rem;
                        font-size: 1.8rem;
                        border: none;
                        background-color: transparent;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &::placeholder {
                            color: #a7a5a5;
                        }

                        &.disabled {
                            background-color: $input-disabled;
                            opacity: 0.5;
                        }
                    }
                    span {
                        position: absolute;
                        align-self: flex-start;
                        // top: 4.6rem;
                        top: 107%;
                        left: 0.1rem;
                        width: 100%;
                        height: 100%;
                        color: red;
                        font-size: 1.5rem;
                        font-weight: 600;
                        border: none;
                        background-color: transparent;
                    }

                    select {
                        background-color: transparent;
                        border: none;
                    }
                    // Radio
                    &.radioContainer {
                        font-size: 1.7rem;
                        border: none;
                        background-color: transparent;
                        box-shadow: initial;
                        border-radius: none;
                        border-color: transparent;
                        padding: none;
                        margin: none;
                        display: flex;
                        align-items: center;

                        .radioContainer__radioLabel {
                            position: initial;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            height: 100%;
                            user-select: none;
                            input {
                                height: 2.4rem;
                                width: 2.4rem;
                                margin-right: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }
}
