.timetable {
    display: flex;
    width: 100%;
    height: auto;
    justify-content: space-evenly;
    align-items: center;
    &__row {
        display: flex;
        width: calc(100% / 7);
        flex-direction: column;
        background-color: #81baf2;
        margin: 0.05rem;
        align-items: center;

        &__day {
            display: flex;
            height: 7rem;
            align-items: center;
            justify-content: center;
            width: 100%;
            p {
                background-color: $blue-sky3;

                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1.5rem;
                height: 4rem;
                width: 85%;
                transition: all 0.3s ease-in-out;
                user-select: none;
                &:hover {
                    background-color: lighten($color: $blue-sky3, $amount: 5);
                }

                &.active {
                    background-color: $white;
                }
            }
        }
        &__selects {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            flex-direction: column;
            height: 13rem;
            width: 100%;

            border-top: 0.05rem solid $white;

            &__select {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 1.7rem;
                width: 80%;

                p {
                    width: 50%;
                }
                select {
                    border: 1px solid #dbdbdb;
                    background-color: $white;
                    box-shadow: 0px 3px 6px #0000001a;
                    border-radius: 10px;
                    border-color: #dbdbdb;
                    height: 4.5rem;
                    font-size: 1.7rem;

                    &:disabled {
                        background-color: darken($color: $white, $amount: 2);
                    }
                }
            }
        }
    }
}
