.fert {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    justify-content: space-between;

    &__up {
        width: 90%;
        height: 28%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &__headers {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            &__matricula {
                width: 30%;
            }
            &__data {
                width: 65%;

                .noInformation {
                    color: $fertilizer-text;
                    font-family: "Noto Sans";
                    font-size: 2rem;
                    font-weight: bold;
                    text-align: center;
                }
            }
        }
    }

    &__down {
        width: 100%;
        height: 65%;
    }
}
