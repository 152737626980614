.quan {
    &__container {
        display: flex;
        flex-direction: column;
        width: calc(100% - 1rem - 1px);
        
        input {
            
        }
    }
}
