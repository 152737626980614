.check-state-pay {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    margin: auto;
    &__form {
        display: flex;
        height: 1.8rem;
        form {
            width: 55%;
            div {
                display: flex;
                position: relative;
                height: 100%;

                input {
                    padding: 10px;
                    width: 100%;
                    box-shadow: 0px 3px 6px $shadow-search;
                    border: 1px solid $filterPaid-back;
                    border-radius: 5px;
                    font-family: "Segoe UI", sans-serif !important;
                }
                span {
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: 100%;
                    color: $gray-logout;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    font-size: 0.8rem;
                    pointer-events: none;
                    i {
                        margin-right: 0.4rem;
                        padding: 6px 0 6px 6px;
                        background-color: $white;
                    }
                }
            }
        }
        &__filter {
            background-color: $blue-icon;
            box-shadow: 0px 3px 6px $shadow-search;
            border-radius: 5px;
            font-size: 0.8rem;
            color: $gray-changePage;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 0.8rem;
            padding: 0.6rem 0.8rem;
        }

        &__filterList {
            background-color: red;
            margin-left: 2rem;
            height: 13rem;
            width: 30%;
            z-index: 7;
            border: 1px solid $filterPaid-back;
            opacity: 1;
            border-radius: 10px;
            backdrop-filter: blur(43px);
            -webkit-backdrop-filter: blur(43px);
            padding: 10px;
        }
    }
    &__list {
        display: flex;
        flex-direction: column;
        &__headers {
            text-align: center;
            display: flex;
            color: $color-black;
            font-weight: bold;
            font-size: calc(8px + 0.4vw);
            margin: 0.8rem 0 0.4rem 0;
            div {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            div:first-child {
                width: 50%;
            }
            div:nth-child(n + 2) {
                width: 15%;

                img {
                    width: 25%;
                }
            }
            div:last-child {
                width: 5%;
            }
        }
        &__container {
            overflow-y: ZQQQQQQQQAQQl;
            height: 22rem;

            &__item {
                display: flex;
                text-align: center;
                margin: 0 0 0.3rem 0;
                p,
                span {
                    padding: 0.5rem 0;
                }
                p.general {
                    text-align: initial;
                    width: 50%;
                    border-radius: 9px 0 0 9px;
                    padding-left: 10px;
                }
                p.total,
                p.paid,
                p.pending {
                    width: 15%;
                }

                p.total {
                    background-color: $white;
                }
                p.pending {
                    background-color: $red-debt;
                }
                p:nth-child(2n + 1) {
                    background-color: $blue-sky4;
                }
                span {
                    background-color: $blue-sky4;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 5%;
                    border-radius: 0 9px 9px 0;
                    color: $gray-inputLogin;
                    cursor: pointer;
                }
            }

            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-thumb {
                background: #ccc;
                border-radius: 2px;
            }

            &::-webkit-scrollbar-thumb:active {
                background-color: #999999;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #b3b3b3;
            }

            /* Estilos track de scroll */
            &::-webkit-scrollbar-track {
                background: transparent;
            }
        }

        .school-list {
        }
    }
}
